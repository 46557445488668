var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "match-height" },
            [
              _c(
                "b-col",
                { attrs: { lg: "12" } },
                [_c("b-card", [_c("AnnualLeaveOverview")], 1)],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "12" } },
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "b-tabs",
                        [
                          _c(
                            "b-tab",
                            { attrs: { title: "Today's leaves", lazy: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "Alert-body mt-2 " },
                                [_c("TodayLeaves")],
                                1
                              )
                            ]
                          ),
                          _c(
                            "b-tab",
                            { attrs: { title: "Scheduled leaves", lazy: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "Alert-body mt-2" },
                                [_c("ScheduledLeaves")],
                                1
                              )
                            ]
                          ),
                          _c(
                            "b-tab",
                            { attrs: { title: "Locked dates", lazy: "" } },
                            [
                              _c(
                                "div",
                                { staticClass: "Alert-body mt-2" },
                                [_c("PracticeLeaves")],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("request-leave", {
        ref: "RequestLeaveSideBar",
        attrs: {
          "is-request-leave-sidebar-active": _vm.isRequestLeaveSidebarActive
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }