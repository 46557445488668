// export default {
//   methods: {
//     showSuccessMessage (text) {
//       this.$vs.notify({
//         title: 'Success',
//         text,
//         color:'#31A9AD',
//         position:'top-right'})
//     }
//   }
// }
