var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-body",
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.graphLoading, rounded: "sm" } },
            [
              _c("b-row", [_c("b-col", { attrs: { cols: "12", sm: "5" } })], 1),
              _c("div", { staticClass: "mb-2" }, [
                _c("h3", { staticClass: "text-center" }, [
                  _vm._v("Annual Leave Overview " + _vm._s(_vm.annualYear))
                ])
              ]),
              _c(
                "div",
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "d-flex justify-content-center" },
                        [
                          _c("v-frappe-chart", {
                            class: { test: _vm.cssSet },
                            attrs: {
                              type: "heatmap",
                              data: _vm.allLeaves,
                              colors: [
                                "#E5E5E5",
                                "#1B9AAA",
                                "#9DE6F4",
                                "#00C49A",
                                "#EFA12C"
                              ],
                              height: 130,
                              radius: 6,
                              start: new Date(_vm.callenderStartDate),
                              end: new Date(_vm.callenderEndDate),
                              tooltipOptions: false
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-5" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "float-right",
                          staticStyle: { "font-size": "11px" },
                          attrs: { cols: "12", sm: "11" }
                        },
                        [
                          _c(
                            "div",
                            [
                              _c(
                                "b-row",
                                { staticClass: "float-right" },
                                [
                                  _c("b-col", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center "
                                      },
                                      [
                                        _c(
                                          "b-avatar",
                                          {
                                            staticClass: "mt-n1",
                                            staticStyle: {
                                              background: "#00C49A !important"
                                            },
                                            attrs: { rounded: "", size: "10" }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                icon: "CheckIcon",
                                                size: "1"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "ml-1" }, [
                                          _c(
                                            "div",
                                            { staticClass: "list-inline mb-0" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 font-weight-bolder"
                                                },
                                                [_vm._v("Today")]
                                              ),
                                              _c("p")
                                            ]
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                        staticStyle: { width: "100px" }
                                      },
                                      [
                                        _c(
                                          "b-avatar",
                                          {
                                            staticClass: "mt-n1",
                                            staticStyle: {
                                              background: "#EFA12C !important"
                                            },
                                            attrs: { rounded: "", size: "10" }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                icon: "CheckIcon",
                                                size: "1"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "ml-1" }, [
                                          _c(
                                            "div",
                                            { staticClass: "list-inline mb-0" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 font-weight-bolder"
                                                },
                                                [_vm._v("Bank Holiday")]
                                              ),
                                              _c("p")
                                            ]
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center",
                                        staticStyle: { width: "80px" }
                                      },
                                      [
                                        _c(
                                          "b-avatar",
                                          {
                                            staticClass: "mt-n1",
                                            staticStyle: {
                                              background: "#1B9AAA !important"
                                            },
                                            attrs: { rounded: "", size: "10" }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                icon: "CheckIcon",
                                                size: "1"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "ml-1" }, [
                                          _c(
                                            "div",
                                            { staticClass: "list-inline mb-0" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 font-weight-bolder"
                                                },
                                                [_vm._v("On leave")]
                                              ),
                                              _c("p")
                                            ]
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ]),
                                  _c("b-col", [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex align-items-center ",
                                        staticStyle: { width: "130px" }
                                      },
                                      [
                                        _c(
                                          "b-avatar",
                                          {
                                            staticClass: "mt-n1",
                                            staticStyle: {
                                              background: "#9DE6F4 !important"
                                            },
                                            attrs: { rounded: "", size: "10" }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                icon: "CheckIcon",
                                                size: "1"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c("div", { staticClass: "ml-1" }, [
                                          _c(
                                            "div",
                                            { staticClass: "list-inline mb-0" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 font-weight-bolder"
                                                },
                                                [_vm._v("No leaves allowed")]
                                              ),
                                              _c("p")
                                            ]
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }