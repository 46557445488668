<template>
  <div class="Home">
    <b-tabs >
      <b-tab title="My leaves" lazy>
        <div class="Alert-body mt-1 "><MyLeaves /></div>
      </b-tab>
      <b-tab title="Dashboard" lazy>
        <div class="Alert-body mt-1"><Dashboard/></div>
      </b-tab>
    </b-tabs>

  </div>

</template>

<script>
import MyLeaves from './views/my-leaves/index'
import Dashboard from './views/dashboard/index'
import Ripple from 'vue-ripple-directive'
import PracticeAPI from '@/apis/modules/practice'
import { BCard, BTab, BTabs, BAvatar, BBadge, BButtonGroup, BButton, BCol, BLink, BRow, BFormGroup } from 'bootstrap-vue'

export default {
  name:'Home',
  components:{
    MyLeaves,
    Dashboard,
    BTab,
    BTabs,
    BAvatar,
    BBadge,
    BButtonGroup,
    BButton,
    BCard,
    BCol,
    BLink,
    BRow,
    BFormGroup
  },
  directives: {
    Ripple,
  },
  data() {

    return {

    }
  },
  methods:{
    async getPracticeDetails(){
      try{
        const Response = await PracticeAPI.getPracticeDetails()
        this.$store.state.userWorkingHoursPerDay = Response.data.data.working_hours_per_day
      }catch(error){
        this.convertAndNotifyError(error)
      }
    }
  }
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
