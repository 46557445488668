var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c(
        "div",
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loading, rounded: "sm" } },
            [
              _c(
                "b-card",
                [
                  _c(
                    "b-row",
                    { staticClass: "my-n2  ml-0 mr-0" },
                    [
                      _c(
                        "b-col",
                        { staticClass: "pb-2 pt-2", attrs: { cols: "10" } },
                        [
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex align-items-center pr-bx"
                              },
                              [
                                _c(
                                  "b-avatar",
                                  {
                                    attrs: {
                                      variant: "light-primary",
                                      rounded: ""
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: {
                                        icon: "CalendarIcon",
                                        size: "18"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "ml-1" }, [
                                  _vm.trackLeaveOnDays
                                    ? _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "mb-0 font-weight-bolder "
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.allocatedLeaveDays) +
                                              " days "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "h4",
                                        {
                                          staticClass:
                                            "mb-0 font-weight-bolder "
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                parseFloat(
                                                  _vm.allocatedLeaveHours
                                                ).toFixed(1)
                                              ) +
                                              "hrs "
                                          )
                                        ]
                                      ),
                                  _c("small", [_vm._v("Allocated leave days")])
                                ])
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex align-items-center pr-bx"
                              },
                              [
                                _c("div", { staticClass: "ml-1" }, [
                                  _vm.trackLeaveOnDays
                                    ? _c(
                                        "h4",
                                        {
                                          staticClass: "mb-0 font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                parseFloat(
                                                  _vm.usedLeaveDays
                                                ).toFixed(1)
                                              ) +
                                              " days "
                                          )
                                        ]
                                      )
                                    : _c(
                                        "h4",
                                        {
                                          staticClass: "mb-0 font-weight-bolder"
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                parseFloat(
                                                  _vm.usedLeaveHours
                                                ).toFixed(1)
                                              ) +
                                              "hrs "
                                          )
                                        ]
                                      ),
                                  _c("small", [_vm._v("Used")])
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex align-items-center pr-bx"
                              },
                              [
                                _c("div", { staticClass: "ml-1" }, [
                                  _c(
                                    "ul",
                                    { staticClass: "list-inline mb-0" },
                                    [
                                      _c(
                                        "li",
                                        { staticClass: "list-inline-item" },
                                        [
                                          _vm.trackLeaveOnDays
                                            ? _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "mb-0 font-weight-bolder"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        parseFloat(
                                                          _vm.allocatedLeaveDays -
                                                            _vm.usedLeaveDays
                                                        ).toFixed(1)
                                                      ) +
                                                      " days "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "h4",
                                                {
                                                  staticClass:
                                                    "mb-0 font-weight-bolder"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        parseFloat(
                                                          _vm.remainingLeaveHours
                                                        ).toFixed(1)
                                                      ) +
                                                      "hrs "
                                                  )
                                                ]
                                              )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("small", [_vm._v("Remaining")])
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex align-items-center ml-1" },
                              [
                                _c(
                                  "b-avatar",
                                  {
                                    attrs: {
                                      variant: "light-warning",
                                      rounded: ""
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      attrs: { icon: "LoaderIcon", size: "18" }
                                    })
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "ml-1" }, [
                                  _c(
                                    "h4",
                                    { staticClass: "mb-0 font-weight-bolder" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.leaveRequestCount) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("small", [_vm._v("Leave requests")])
                                ])
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass: "pt-0 pb-2 pt-xl-2 pb-xl-0 ml-n1 ",
                          attrs: { cols: "2" }
                        },
                        [
                          _c("b-row", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-end  mt-2 mt-md-0 pl-0 pl-md-0 ml-auto"
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "float-right",
                                    attrs: { size: "md", variant: "primary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.requestLeave()
                                      }
                                    }
                                  },
                                  [_vm._v("Request Leave ")]
                                )
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "match-height" },
            [
              _c(
                "b-col",
                { attrs: { lg: "12" } },
                [
                  _c(
                    "b-card",
                    [
                      _c("ScheduledLeaves", {
                        ref: "scheduledLeaves",
                        on: {
                          summaryRefresh: function($event) {
                            return _vm.getUserSummaryDetails()
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "b-row",
            { staticClass: "match-height" },
            [
              _c(
                "b-col",
                { attrs: { lg: "12" } },
                [_c("b-card", [_c("PastLeaves")], 1)],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("request-leave", {
        ref: "RequestLeaveSideBar",
        attrs: {
          "is-request-leave-sidebar-active": _vm.isRequestLeaveSidebarActive
        },
        on: {
          requestLeave: function($event) {
            return _vm.requestLeave()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }