<template>
  <div>

    <div>
      <b-row class="match-height">
        <b-col lg="12">
          <b-card>
            <AnnualLeaveOverview />
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div>
      <b-row>
        <b-col lg="12">
          <b-card>
          <b-tabs >
            <b-tab title="Today's leaves" lazy>
              <div class="Alert-body mt-2 "><TodayLeaves /></div>
            </b-tab>
            <b-tab title="Scheduled leaves" lazy>
              <div class="Alert-body mt-2"><ScheduledLeaves /></div>
            </b-tab>
            <b-tab title="Locked dates" lazy>
              <div class="Alert-body mt-2"><PracticeLeaves /></div>
            </b-tab>
          </b-tabs>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <request-leave
        ref="RequestLeaveSideBar"
        :is-request-leave-sidebar-active="isRequestLeaveSidebarActive"
    />
  </div>
</template>


<script>

import AnnualLeaveOverview from './annualLeaveOverview'
import TodayLeaves from './leaves/todayLeaves'
import PracticeLeaves from './leaves/practiceLeaves.vue'
import ScheduledLeaves from './leaves/scheduledLeaves'
import {BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow, BTab, BTabs} from 'bootstrap-vue'

export default {
  components: {
    AnnualLeaveOverview,
    ScheduledLeaves,
    TodayLeaves,
    PracticeLeaves,
    BCard,
    BTab,
    BTabs,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
  },
  data() {
    return {
    }
  },

}
</script>

<style scoped>

</style>

