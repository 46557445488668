<template>
  <div>
    <b-card class="mb-0" no-body>
      <div>
        <b-row >
          <h3 class="ml-1 mb-1">
            Scheduled Leaves
          </h3>
        </b-row>

      </div>
      <div>
        <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
          <b-row>
            <b-col >
              <b-table
                  ref="table"
                  :current-page="currentPage"
                  :per-page="pagination.perPage"
                  :fields="fields"
                  :items="getScheduledLeaves"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  class="position-relative mobile_table_css" empty-text=""
                  hover
                  primary-key="id"
                  responsive
                  show-empty
              >
                <template #cell(date)="data">
                  <div style="width: 150px">
                    <b-row class="center text-primary">
                      <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.date}}</p>
                    </b-row>
                  </div>
                </template>
                <template #cell(time)="data">
                  <div style="width: 80px">
                    <b-row class="center text-primary">
                      <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.time}}</p>
                    </b-row>
                  </div>
                </template>

                <template #cell(status)="data">
                  <b-row v-if="data.item.status == 'Requested'">
                    <b-col class="">
                      <b-badge variant="light-warning" >
                        Pending review
                      </b-badge>
                    </b-col>
                  </b-row>
                  <b-row  v-else-if="data.item.status == 'Approved'">
                    <b-col class="">
                      <b-badge variant="light-success" >
                        {{ data.item.status }}
                      </b-badge>
                    </b-col>
                  </b-row>
                  <b-row v-else>
                    <b-col class="">
                      <b-badge variant="light-danger" >
                        {{ data.item.status }}
                      </b-badge>
                    </b-col>
                  </b-row>
                </template>
                <template #cell(action)="data">
                  <b-row>
                    <b-col class=" ml-n3 mr-n3">
                      <b-button
                          size="23"
                          variant="outline-white"
                          class="btn-icon shadow-sm bg-white text-primary"
                          v-b-tooltip.hover.top="'Edit'"
                          @click="editLeave(data.item)">

                        <feather-icon
                            size="18"
                            icon="EditIcon"
                        />
                      </b-button>
                    </b-col>
                    <b-col class="ml-1">
                      <b-button
                      v-if="data.item.status !== 'Cancelled'"
                      variant="outline-white"
                      class="shadow-sm bg-white text-danger"
                      v-b-tooltip.hover.top="'Cancel'"
                      @click="canceltLeave(data.item.id)"
                      >
                        Cancel
                      </b-button>
                    </b-col>
                  </b-row>
                </template>

              </b-table>
            </b-col>
          </b-row>
        </b-overlay>

        <!--                pagination-->
        <div class="mx-1 mb-2">
          <b-row>
            <b-col cols="12" v-if="noDataTable === 0" class="text-center">
              <span >No data for preview</span>
            </b-col>
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
                cols="12"
                sm="6"
            >
            <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
              <!-- <span class="text-muted" v-if="pagination.totalRows !==0">Showing 1 to 4 of 4 entries</span> -->
            </b-col>
            <!-- Pagination -->
            <b-col
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
                cols="12"
                sm="6"
            >

              <b-pagination
                  v-model="currentPage"
                  :per-page="pagination.perPage"
                  :total-rows="pagination.totalRows"
                  class="mb-0 mt-1 mt-sm-0 "
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </div>
    </b-card>
    <request-leave
        ref="RequestLeaveSideBar"
        :is-edit-leave-sidebar-active="isEditLeaveSidebarActive"
        :staff-details = "staffMember"
        @toggleSideBars="toggle()"
    />
  </div>
</template>

<script>

import {
  BAvatar,
  BAvatarGroup,
  BBadge,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BOverlay,
  BInputGroupPrepend,
  BLink,
  BMedia,
  BPagination,
  BRow,
  BTable,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import RequestLeave from '@/views/leave-management/views/my-leaves/sidebars/editLeaveSidebar'
import SalariedStaffLeaveAPI from '@/apis/modules/leave_managment_salaried_staff'
import ProfileAPI from '@/apis/modules/profile'
import DateDiff from 'date-diff'
export default {
  name: 'map-list',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BFormGroup,
    BInputGroupPrepend,
    BAvatarGroup,
    BOverlay,
    RequestLeave,
    vSelect,
    FeatherIcon,
    VBTooltip
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  data() {
    return {
      isEditLeaveSidebarActive: false,
      currentPage: 1,
      staffMember:{},
      tableLoading: false,
      filters:{},
      noDataTable:'',
      filter: null,
      filterOn: [],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      currentUserId:'',
      fields: [
        {
          key: 'date',
          label: 'DATE',
          sortable: true
        },
        {
          key: 'time',
          label: 'TIME'
        },
        {
          key: 'duration',
          label: 'DURATION'
        },
        {
          key: 'reason',
          label: 'Leave TYPE'
        },
        {
          key: 'approved_by',
          label: 'APPROVED BY'
        },
        {
          key: 'status',
          label: 'STATUS',
          sortable: false
        },
        {
          key: 'action',
          label: '',
          sortable: false
        }
      ],
    }
  },
  methods: {
    editLeave(data) {
      this.staffMember = data
      this.isEditLeaveSidebarActive = !this.isEditLeaveSidebarActive
    },
    async getCurrentUser(){
      const user =  (await ProfileAPI.getCurrentUser()).data.data
      this.currentUserId = user.id
    },
    async getScheduledLeaves(){

      try{
        this.tableLoading = true
        await this.getCurrentUser()
        const Response = await SalariedStaffLeaveAPI.usersScheduledLeaves( this.currentUserId ,5, this.currentPage)
        let dataArray =  Response.data.data.map((x) => ({
          id: x.id,
          date: x.is_day?`${this.momentFormat(x.start,'DD-MMM-YY')}`+` - ` +`${ this.momentFormat(x.end,'DD-MMM-YY')}`:this.momentFormat(x.start,'DD-MMM-YY'),
          start: x.start,
          is_day: x.is_day,
          end: x.end,
          duration: x.is_day?`${new DateDiff( new Date(x.end), new Date(x.start)).days()} days`: `${new DateDiff( new Date(x.end), new Date(x.start)).hours()} hours`,
          reason: x.sickness_type_id && x.leave_reason_id == 1 ? x.sickness_type.name : x.leave_reason_id ? x.leave_reason.name : 'N/A',
          reason_id: x.leave_reason_id === null ? 'N/A' : x.leave_reason.id,
          sickness_id: x.sickness_type_id === null ? 'N/A' : x.sickness_type.id,
          user: x.user === null? 'N/A':x.user.first_name +' '+ x.user.last_name,
          user_id: x.user.id,
          approved_by: x.administrator === null ? 'N/A' : `${x.administrator.first_name}  ${x.administrator.last_name}`,
          status: x.state.charAt(0).toUpperCase() + x.state.substring(1),
          time:x.is_day?'Full day': `${this.momentFormat(x.start,'HH:mm')}` + ' - ' + `${this.momentFormat(x.end,'HH:mm')}`
        }))
        this.tableLoading = false
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to

        return dataArray
      }catch(error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async canceltLeave(id)
    {
      try {
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure you want to cancel this leave?',
          text: '',
          imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: 'Yes, cancel',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(async result => {
          if (result.value) {
            await SalariedStaffLeaveAPI.cancelLeave(id)
            this.$refs.table.refresh()
            this.tableLoading = false
            this.$emit('summaryRefresh')
          }
        })
        this.$refs.table.refresh()
        this.tableLoading = false

      }catch (error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    refreshTable(){
      this.$refs.table.refresh()
    },
    async toggle() {
      this.isEditLeaveSidebarActive = !this.isEditLeaveSidebarActive
      this.$refs.table.refresh()
      if(!this.isEditLeaveSidebarActive){
        this.$emit('summaryRefresh')
      }
    }
  },
  async mounted(){
    await this.getCurrentUser()
  }
}
</script>

<style scoped>
.button-size{
  height: 38px;
}

</style>
