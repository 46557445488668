function generateDataHeatMap(count, yrange) {
    let i = 0;
    const series = [];
    while (i < count) {
        const x = `${(i + 1).toString()}`;
        const y =
            Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;
        series.push({
            x,
            y,
        });
        // eslint-disable-next-line no-plusplus
        i++;
    }
    return series;
}

export default {
    heatMapChart: {
        series: [
            {
                name: 'DEC',
                data: [
                    {
                        x: 1,
                        y: 0,
                    },
                    {
                        x: 2,
                        y: 0,
                    },
                    {
                        x: 3,
                        y: 0,
                    },
                    {
                        x: 4,
                        y: 0,
                    },
                    {
                        x: 5,
                        y: 0,
                    },
                    {
                        x: 6,
                        y: 0,
                    },
                    {
                        x: 7,
                        y: 4,
                    },
                    {
                        x: 8,
                        y: 4,
                    },
                    {
                        x: 9,
                        y: 4,
                    },
                    {
                        x: 10,
                        y: 0,
                    },
                    {
                        x: 11,
                        y: 0,
                    },
                    {
                        x: 12,
                        y: 0,
                    },
                    {
                        x: 13,
                        y: 0,
                    },
                    {
                        x: 14,
                        y: 0,
                    },
                    {
                        x: 15,
                        y: 4,
                    },
                    {
                        x: 16,
                        y: 4,
                    },
                    {
                        x: 17,
                        y: 4,
                    },
                    {
                        x: 18,
                        y: 4,
                    },
                    {
                        x: 19,
                        y: 0,
                    },
                    {
                        x: 20,
                        y: 0,
                    },
                    {
                        x: 21,
                        y: 0,
                    },
                    {
                        x: 22,
                        y: 0,
                    },
                    {
                        x: 23,
                        y: 0,
                    },
                    {
                        x: 24,
                        y: 0,
                    },
                    {
                        x: 25,
                        y: 0,
                    },
                    {
                        x: 26,
                        y: 0,
                    },
                    {
                        x: 27,
                        y: 2,
                    },
                    {
                        x: 28,
                        y: 0,
                    },
                    {
                        x: 29,
                        y: 0,
                    },
                    {
                        x: 30,
                        y: 0,
                    },
                    {
                        x: 31,
                        y: 4,
                    },
                ],
            },
            {
                name: 'NOV',
                data: [
                    {
                        x: 1,
                        y: 4,
                    },
                    {
                        x: 2,
                        y: 4,
                    },
                    {
                        x: 3,
                        y: 0,
                    },
                    {
                        x: 4,
                        y: 4,
                    },
                    {
                        x: 5,
                        y: 0,
                    },
                    {
                        x: 6,
                        y: 0,
                    },
                    {
                        x: 7,
                        y: 0,
                    },
                    {
                        x: 8,
                        y: 0,
                    },
                    {
                        x: 9,
                        y: 1,
                    },
                    {
                        x: 10,
                        y: 1,
                    },
                    {
                        x: 11,
                        y: 1,
                    },
                    {
                        x: 12,
                        y: 2,
                    },
                    {
                        x: 13,
                        y: 2,
                    },
                    {
                        x: 14,
                        y: 0,
                    },
                    {
                        x: 15,
                        y: 0,
                    },
                    {
                        x: 16,
                        y: 0,
                    },
                    {
                        x: 17,
                        y: 0,
                    },
                    {
                        x: 18,
                        y: 0,
                    },
                    {
                        x: 19,
                        y: 0,
                    },
                    {
                        x: 20,
                        y: 0,
                    },
                    {
                        x: 21,
                        y: 0,
                    },
                    {
                        x: 22,
                        y: 0,
                    },
                    {
                        x: 23,
                        y: 0,
                    },
                    {
                        x: 24,
                        y: 0,
                    },
                    {
                        x: 25,
                        y: 0,
                    },
                    {
                        x: 26,
                        y: 0,
                    },
                    {
                        x: 27,
                        y: 0,
                    },
                    {
                        x: 28,
                        y: 0,
                    },
                    {
                        x: 29,
                        y: 0,
                    },
                    {
                        x: 30,
                        y: 4,
                    },
                ],
            },
            {
                name: 'OCT',
                data: [
                    {
                        x: 1,
                        y: 0,
                    },
                    {
                        x: 2,
                        y: 0,
                    },
                    {
                        x: 3,
                        y: 2,
                    },
                    {
                        x: 4,
                        y: 0,
                    },
                    {
                        x: 5,
                        y: 0,
                    },
                    {
                        x: 6,
                        y: 0,
                    },
                    {
                        x: 7,
                        y: 0,
                    },
                    {
                        x: 8,
                        y: 3,
                    },
                    {
                        x: 9,
                        y: 0,
                    },
                    {
                        x: 10,
                        y: 0,
                    },
                    {
                        x: 11,
                        y: 4,
                    },
                    {
                        x: 12,
                        y: 4,
                    },
                    {
                        x: 13,
                        y: 4,
                    },
                    {
                        x: 14,
                        y: 4,
                    },
                    {
                        x: 15,
                        y: 4,
                    },
                    {
                        x: 16,
                        y: 0,
                    },
                    {
                        x: 17,
                        y: 0,
                    },
                    {
                        x: 18,
                        y: 0,
                    },
                    {
                        x: 19,
                        y: 0,
                    },
                    {
                        x: 20,
                        y: 0,
                    },
                    {
                        x: 21,
                        y: 0,
                    },
                    {
                        x: 22,
                        y: 0,
                    },
                    {
                        x: 23,
                        y: 0,
                    },
                    {
                        x: 24,
                        y: 0,
                    },
                    {
                        x: 25,
                        y: 0,
                    },
                    {
                        x: 26,
                        y: 1,
                    },
                    {
                        x: 27,
                        y: 4,
                    },
                    {
                        x: 28,
                        y: 1,
                    },
                    {
                        x: 29,
                        y: 0,
                    },
                    {
                        x: 30,
                        y: 0,
                    },
                    {
                        x: 31,
                        y: 1,
                    },
                ],
            },
            {
                name: 'SEP',
                data: [
                    {
                        x: 1,
                        y: 4,
                    },
                    {
                        x: 2,
                        y: 4,
                    },
                    {
                        x: 3,
                        y: 0,
                    },
                    {
                        x: 4,
                        y: 0,
                    },
                    {
                        x: 5,
                        y: 0,
                    },
                    {
                        x: 6,
                        y: 0,
                    },
                    {
                        x: 7,
                        y: 0,
                    },
                    {
                        x: 8,
                        y: 0,
                    },
                    {
                        x: 9,
                        y: 0,
                    },
                    {
                        x: 10,
                        y: 0,
                    },
                    {
                        x: 11,
                        y: 0,
                    },
                    {
                        x: 12,
                        y: 0,
                    },
                    {
                        x: 13,
                        y: 4,
                    },
                    {
                        x: 14,
                        y: 4,
                    },
                    {
                        x: 15,
                        y: 4,
                    },
                    {
                        x: 16,
                        y: 0,
                    },
                    {
                        x: 17,
                        y: 0,
                    },
                    {
                        x: 18,
                        y: 0,
                    },
                    {
                        x: 19,
                        y: 0,
                    },
                    {
                        x: 20,
                        y: 0,
                    },
                    {
                        x: 21,
                        y: 0,
                    },
                    {
                        x: 22,
                        y: 0,
                    },
                    {
                        x: 23,
                        y: 0,
                    },
                    {
                        x: 24,
                        y: 0,
                    },
                    {
                        x: 25,
                        y: 2,
                    },
                    {
                        x: 26,
                        y: 0,
                    },
                    {
                        x: 27,
                        y: 0,
                    },
                    {
                        x: 28,
                        y: 1,
                    },
                    {
                        x: 29,
                        y: 1,
                    },
                    {
                        x: 30,
                        y: 1,
                    },
                ],
            },
            {
                name: 'AUG',
                data: [
                    {
                        x: 1,
                        y: 0,
                    },
                    {
                        x: 2,
                        y: 4,
                    },
                    {
                        x: 3,
                        y: 4,
                    },
                    {
                        x: 4,
                        y: 4,
                    },
                    {
                        x: 5,
                        y: 0,
                    },
                    {
                        x: 6,
                        y: 0,
                    },
                    {
                        x: 7,
                        y: 0,
                    },
                    {
                        x: 8,
                        y: 0,
                    },
                    {
                        x: 9,
                        y: 0,
                    },
                    {
                        x: 10,
                        y: 0,
                    },
                    {
                        x: 11,
                        y: 2,
                    },
                    {
                        x: 12,
                        y: 0,
                    },
                    {
                        x: 13,
                        y: 0,
                    },
                    {
                        x: 14,
                        y: 0,
                    },
                    {
                        x: 15,
                        y: 0,
                    },
                    {
                        x: 16,
                        y: 1,
                    },
                    {
                        x: 17,
                        y: 1,
                    },
                    {
                        x: 18,
                        y: 1,
                    },
                    {
                        x: 19,
                        y: 1,
                    },
                    {
                        x: 20,
                        y: 0,
                    },
                    {
                        x: 21,
                        y: 0,
                    },
                    {
                        x: 22,
                        y: 0,
                    },
                    {
                        x: 23,
                        y: 0,
                    },
                    {
                        x: 24,
                        y: 0,
                    },
                    {
                        x: 25,
                        y: 0,
                    },
                    {
                        x: 26,
                        y: 0,
                    },
                    {
                        x: 27,
                        y: 0,
                    },
                    {
                        x: 28,
                        y: 0,
                    },
                    {
                        x: 29,
                        y: 4,
                    },
                    {
                        x: 30,
                        y: 4,
                    },
                    {
                        x: 31,
                        y: 0,
                    },
                ],
            },
            {
                name: 'JUL',
                data: [
                    {
                        x: 1,
                        y: 4,
                    },
                    {
                        x: 2,
                        y: 4,
                    },
                    {
                        x: 3,
                        y: 0,
                    },
                    {
                        x: 4,
                        y: 0,
                    },
                    {
                        x: 5,
                        y: 0,
                    },
                    {
                        x: 6,
                        y: 4,
                    },
                    {
                        x: 7,
                        y: 4,
                    },
                    {
                        x: 8,
                        y: 4,
                    },
                    {
                        x: 9,
                        y: 0,
                    },
                    {
                        x: 10,
                        y: 2,
                    },
                    {
                        x: 11,
                        y: 0,
                    },
                    {
                        x: 12,
                        y: 1,
                    },
                    {
                        x: 13,
                        y: 1,
                    },
                    {
                        x: 14,
                        y: 1,
                    },
                    {
                        x: 15,
                        y: 1,
                    },
                    {
                        x: 16,
                        y: 1,
                    },
                    {
                        x: 17,
                        y: 0,
                    },
                    {
                        x: 18,
                        y: 0,
                    },
                    {
                        x: 19,
                        y: 0,
                    },
                    {
                        x: 20,
                        y: 0,
                    },
                    {
                        x: 21,
                        y: 0,
                    },
                    {
                        x: 22,
                        y: 0,
                    },
                    {
                        x: 23,
                        y: 4,
                    },
                    {
                        x: 24,
                        y: 4,
                    },
                    {
                        x: 25,
                        y: 0,
                    },
                    {
                        x: 26,
                        y: 0,
                    },
                    {
                        x: 27,
                        y: 0,
                    },
                    {
                        x: 28,
                        y: 0,
                    },
                    {
                        x: 29,
                        y: 2,
                    },
                    {
                        x: 30,
                        y: 0,
                    },
                    {
                        x: 31,
                        y: 0,
                    },
                ],
            },
            {
                name: 'JUN',
                data: [
                    {
                        x: 1,
                        y: 0,
                    },
                    {
                        x: 2,
                        y: 0,
                    },
                    {
                        x: 3,
                        y: 0,
                    },
                    {
                        x: 4,
                        y: 0,
                    },
                    {
                        x: 5,
                        y: 2,
                    },
                    {
                        x: 6,
                        y: 0,
                    },
                    {
                        x: 7,
                        y: 4,
                    },
                    {
                        x: 8,
                        y: 0,
                    },
                    {
                        x: 9,
                        y: 4,
                    },
                    {
                        x: 10,
                        y: 4,
                    },
                    {
                        x: 11,
                        y: 4,
                    },
                    {
                        x: 12,
                        y: 0,
                    },
                    {
                        x: 13,
                        y: 0,
                    },
                    {
                        x: 14,
                        y: 4,
                    },
                    {
                        x: 15,
                        y: 4,
                    },
                    {
                        x: 16,
                        y: 4,
                    },
                    {
                        x: 17,
                        y: 0,
                    },
                    {
                        x: 18,
                        y: 0,
                    },
                    {
                        x: 19,
                        y: 0,
                    },
                    {
                        x: 20,
                        y: 0,
                    },
                    {
                        x: 21,
                        y: 0,
                    },
                    {
                        x: 22,
                        y: 0,
                    },
                    {
                        x: 23,
                        y: 2,
                    },
                    {
                        x: 24,
                        y: 0,
                    },
                    {
                        x: 25,
                        y: 0,
                    },
                    {
                        x: 26,
                        y: 0,
                    },
                    {
                        x: 27,
                        y: 0,
                    },
                    {
                        x: 28,
                        y: 0,
                    },
                    {
                        x: 29,
                        y: 0,
                    },
                    {
                        x: 30,
                        y: 0,
                    },
                ],
            },
            {
                name: 'MAY',
                data: [
                    {
                        x: 1,
                        y: 1,
                    },
                    {
                        x: 2,
                        y: 0,
                    },
                    {
                        x: 3,
                        y: 4,
                    },
                    {
                        x: 4,
                        y: 0,
                    },
                    {
                        x: 5,
                        y: 4,
                    },
                    {
                        x: 6,
                        y: 0,
                    },
                    {
                        x: 7,
                        y: 1,
                    },
                    {
                        x: 8,
                        y: 1,
                    },
                    {
                        x: 9,
                        y: 0,
                    },
                    {
                        x: 10,
                        y: 4,
                    },
                    {
                        x: 11,
                        y: 1,
                    },
                    {
                        x: 12,
                        y: 0,
                    },
                    {
                        x: 13,
                        y: 0,
                    },
                    {
                        x: 14,
                        y: 1,
                    },
                    {
                        x: 15,
                        y: 4,
                    },
                    {
                        x: 16,
                        y: 4,
                    },
                    {
                        x: 17,
                        y: 0,
                    },
                    {
                        x: 18,
                        y: 1,
                    },
                    {
                        x: 19,
                        y: 0,
                    },
                    {
                        x: 20,
                        y: 0,
                    },
                    {
                        x: 21,
                        y: 4,
                    },
                    {
                        x: 22,
                        y: 4,
                    },
                    {
                        x: 23,
                        y: 2,
                    },
                    {
                        x: 24,
                        y: 0,
                    },
                    {
                        x: 25,
                        y: 0,
                    },
                    {
                        x: 26,
                        y: 4,
                    },
                    {
                        x: 27,
                        y: 0,
                    },
                    {
                        x: 28,
                        y: 4,
                    },
                    {
                        x: 29,
                        y: 0,
                    },
                    {
                        x: 30,
                        y: 4,
                    },
                    {
                        x: 31,
                        y: 4,
                    },
                ],
            },
            {
                name: 'APR',
                data: [
                    {
                        x: 1,
                        y: 0,
                    },
                    {
                        x: 2,
                        y: 1,
                    },
                    {
                        x: 3,
                        y: 1,
                    },
                    {
                        x: 4,
                        y: 0,
                    },
                    {
                        x: 5,
                        y: 4,
                    },
                    {
                        x: 6,
                        y: 0,
                    },
                    {
                        x: 7,
                        y: 1,
                    },
                    {
                        x: 8,
                        y: 1,
                    },
                    {
                        x: 9,
                        y: 0,
                    },
                    {
                        x: 10,
                        y: 0,
                    },
                    {
                        x: 11,
                        y: 1,
                    },
                    {
                        x: 12,
                        y: 0,
                    },
                    {
                        x: 13,
                        y: 0,
                    },
                    {
                        x: 14,
                        y: 4,
                    },
                    {
                        x: 15,
                        y: 0,
                    },
                    {
                        x: 16,
                        y: 0,
                    },
                    {
                        x: 17,
                        y: 1,
                    },
                    {
                        x: 18,
                        y: 1,
                    },
                    {
                        x: 19,
                        y: 0,
                    },
                    {
                        x: 20,
                        y: 0,
                    },
                    {
                        x: 21,
                        y: 4,
                    },
                    {
                        x: 22,
                        y: 4,
                    },
                    {
                        x: 23,
                        y: 0,
                    },
                    {
                        x: 24,
                        y: 0,
                    },
                    {
                        x: 25,
                        y: 0,
                    },
                    {
                        x: 26,
                        y: 0,
                    },
                    {
                        x: 27,
                        y: 0,
                    },
                    {
                        x: 28,
                        y: 1,
                    },
                    {
                        x: 29,
                        y: 4,
                    },
                    {
                        x: 30,
                        y: 1,
                    },
                ],
            },
            {
                name: 'MAR',
                data: [
                    {
                        x: 1,
                        y: 0,
                    },
                    {
                        x: 2,
                        y: 1,
                    },
                    {
                        x: 3,
                        y: 1,
                    },
                    {
                        x: 4,
                        y: 0,
                    },
                    {
                        x: 5,
                        y: 4,
                    },
                    {
                        x: 6,
                        y: 0,
                    },
                    {
                        x: 7,
                        y: 1,
                    },
                    {
                        x: 8,
                        y: 1,
                    },
                    {
                        x: 9,
                        y: 0,
                    },
                    {
                        x: 10,
                        y: 4,
                    },
                    {
                        x: 11,
                        y: 1,
                    },
                    {
                        x: 12,
                        y: 0,
                    },
                    {
                        x: 13,
                        y: 0,
                    },
                    {
                        x: 14,
                        y: 2,
                    },
                    {
                        x: 15,
                        y: 4,
                    },
                    {
                        x: 16,
                        y: 4,
                    },
                    {
                        x: 17,
                        y: 0,
                    },
                    {
                        x: 18,
                        y: 1,
                    },
                    {
                        x: 19,
                        y: 4,
                    },
                    {
                        x: 20,
                        y: 0,
                    },
                    {
                        x: 21,
                        y: 0,
                    },
                    {
                        x: 22,
                        y: 0,
                    },
                    {
                        x: 23,
                        y: 0,
                    },
                    {
                        x: 24,
                        y: 0,
                    },
                    {
                        x: 25,
                        y: 0,
                    },
                    {
                        x: 26,
                        y: 4,
                    },
                    {
                        x: 27,
                        y: 0,
                    },
                    {
                        x: 28,
                        y: 0,
                    },
                    {
                        x: 29,
                        y: 4,
                    },
                    {
                        x: 30,
                        y: 0,
                    },
                    {
                        x: 31,
                        y: 1,
                    },
                ],
            },
            {
                name: 'FEB',
                data: [
                    {
                        x: 1,
                        y: 4,
                    },
                    {
                        x: 2,
                        y: 4,
                    },
                    {
                        x: 3,
                        y: 0,
                    },
                    {
                        x: 4,
                        y: 0,
                    },
                    {
                        x: 5,
                        y: 0,
                    },
                    {
                        x: 6,
                        y: 0,
                    },
                    {
                        x: 7,
                        y: 0,
                    },
                    {
                        x: 8,
                        y: 0,
                    },
                    {
                        x: 9,
                        y: 0,
                    },
                    {
                        x: 10,
                        y: 4,
                    },
                    {
                        x: 11,
                        y: 0,
                    },
                    {
                        x: 12,
                        y: 0,
                    },
                    {
                        x: 13,
                        y: 0,
                    },
                    {
                        x: 14,
                        y: 4,
                    },
                    {
                        x: 15,
                        y: 4,
                    },
                    {
                        x: 16,
                        y: 2,
                    },
                    {
                        x: 17,
                        y: 0,
                    },
                    {
                        x: 18,
                        y: 0,
                    },
                    {
                        x: 19,
                        y: 4,
                    },
                    {
                        x: 20,
                        y: 0,
                    },
                    {
                        x: 21,
                        y: 4,
                    },
                    {
                        x: 22,
                        y: 4,
                    },
                    {
                        x: 23,
                        y: 0,
                    },
                    {
                        x: 24,
                        y: 0,
                    },
                    {
                        x: 25,
                        y: 0,
                    },
                    {
                        x: 26,
                        y: 4,
                    },
                    {
                        x: 27,
                        y: 0,
                    },
                    {
                        x: 28,
                        y: 0,
                    },
                    {
                        x: 29,
                        y: 0,
                    },
                ],
            },
            {
                name: 'JAN',
                data: [
                    {
                        x: 1,
                        y: 1,
                    },
                    {
                        x: 2,
                        y: 0,
                    },
                    {
                        x: 3,
                        y: 4,
                    },
                    {
                        x: 4,
                        y: 0,
                    },
                    {
                        x: 5,
                        y: 4,
                    },
                    {
                        x: 6,
                        y: 0,
                    },
                    {
                        x: 7,
                        y: 1,
                    },
                    {
                        x: 8,
                        y: 1,
                    },
                    {
                        x: 9,
                        y: 0,
                    },
                    {
                        x: 10,
                        y: 4,
                    },
                    {
                        x: 11,
                        y: 1,
                    },
                    {
                        x: 12,
                        y: 0,
                    },
                    {
                        x: 13,
                        y: 0,
                    },
                    {
                        x: 14,
                        y: 1,
                    },
                    {
                        x: 15,
                        y: 4,
                    },
                    {
                        x: 16,
                        y: 4,
                    },
                    {
                        x: 17,
                        y: 0,
                    },
                    {
                        x: 18,
                        y: 1,
                    },
                    {
                        x: 19,
                        y: 0,
                    },
                    {
                        x: 20,
                        y: 0,
                    },
                    {
                        x: 21,
                        y: 4,
                    },
                    {
                        x: 22,
                        y: 4,
                    },
                    {
                        x: 23,
                        y: 2,
                    },
                    {
                        x: 24,
                        y: 0,
                    },
                    {
                        x: 25,
                        y: 0,
                    },
                    {
                        x: 26,
                        y: 4,
                    },
                    {
                        x: 27,
                        y: 0,
                    },
                    {
                        x: 28,
                        y: 4,
                    },
                    {
                        x: 29,
                        y: 0,
                    },
                    {
                        x: 30,
                        y: 4,
                    },
                    {
                        x: 31,
                        y: 4,
                    },
                ],
            },


        ],

        chartOptions: {
            dataLabels: {
                enabled: false,
            },
            tooltip: {
                enabled: false,
            },
            selection: {
                enabled: false,
            },
            legend: {
                show: false,
                position: 'top',
            },
            colors: ['#E5E5E5'],
            plotOptions: {
                heatmap: {
                    enableShades: false,

                    colorScale: {
                        ranges: [
                            {
                                from: 0,
                                to: 0,
                                name: '0-10',
                                color: '#E5E5E5',
                            },
                            {
                                from: 1,
                                to: 1,
                                name: '0-10',
                                color: '#CCE6F4',
                            },
                            {
                                from: 2,
                                to: 2,
                                name: '10-20',
                                color: '#EFA12C',
                            },
                            {
                                from: 3,
                                to: 3,
                                name: '20-30',
                                color: '#00C49A',
                            },
                            {
                                from: 4,
                                to: 4,
                                name: '50-60',
                                color: '#1B9AAA',
                            },
                        ],
                    },
                },
            },
            xaxis: {
                labels: {
                    show: true,
                },
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false,
                },
            },
            chart: {
                height: 350,
                type: 'heatmap',
                toolbar: {
                    show: false,
                },
            },
        },
    },
};
