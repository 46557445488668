var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Home" },
    [
      _c(
        "b-tabs",
        [
          _c("b-tab", { attrs: { title: "My leaves", lazy: "" } }, [
            _c("div", { staticClass: "Alert-body mt-1 " }, [_c("MyLeaves")], 1)
          ]),
          _c("b-tab", { attrs: { title: "Dashboard", lazy: "" } }, [
            _c("div", { staticClass: "Alert-body mt-1" }, [_c("Dashboard")], 1)
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }