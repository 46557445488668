<template>
  <b-row>

    <b-col cols="12">
      <b-overlay
            :show="tableLoading"
            rounded="sm"
        >
      <b-table
          :current-page="currentPage"
          :fields="fields"
          :filter="filter"
          :filter-included-fields="filterOn"
          :items="getTodaysLeaves"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          hover
          class="mobile_table_css"
          responsive
      >
        <!-- Name $ Avatar -->

        <template #cell(user)="data">
          <b-row class="center text-primary">
              <b-avatar
                size="40"
                :src="getUserImage(data.item.user_id)"
              />
              <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.user}}</p>
            <br/>
          </b-row>
        </template>


      </b-table>
      </b-overlay>
    </b-col>


    <!-- Pagination -->

    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start" >
      <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
    </b-col>

    <!-- Pagination -->

    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end" >

      <b-pagination
        v-model="currentPage"
        :per-page="pagination.perPage"
        :total-rows="pagination.totalRows"
        class="mb-0 mt-1 mt-sm-0 "
        first-number
        last-number
        next-class="next-item"
        prev-class="prev-item">

        <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>

        <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
        </template>

    </b-pagination>

    </b-col>
  </b-row>
</template>

<script>
import SalariedStaffLeaveAPI from '@/apis/modules/leave_managment_salaried_staff'
import {BDropdown, BDropdownItem} from 'bootstrap-vue'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import MomentMixin from '@/mixins/MomentMixin'
import DateDiff from 'date-diff'
import Ripple from 'vue-ripple-directive'
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BOverlay
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BOverlay
  },
  directives: {
    Ripple,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin],
  data() {
    return {
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      tableLoading: false,
      fields: [
        {
          key: 'user',
          label: 'NAME',
        },
        {
          key: 'date',
          label: 'DATE',
          sortable: false
        },
        {
          key: 'time',
          label: 'TIME',
        },
        {
          key: 'duration',
          label: 'DURATION',
        },
        // {
        //   key: 'reason',
        //   label: 'REASON',
        // },
        // {
        //   key: 'approved_by',
        //   label: 'APPROVED BY',
        // },


      ],
    }
  },
  methods:{
    async getTodaysLeaves(){
      try{
        this.tableLoading = true
        const Response = await SalariedStaffLeaveAPI.getTodaysLeaves( 5, this.currentPage)
        let dataArray =  Response.data.data.map((x) => ({
          id: x.id,
          date: x.is_day?`${this.momentFormat(x.start,'DD-MMM-YY')}`+` - ` +`${ this.momentFormat(x.end,'DD-MMM-YY')}`:this.momentFormat(x.start,'DD-MMM-YY'),
          start: x.start,
          is_day: x.is_day,
          end: x.end,
          duration: x.is_day?`${new DateDiff( new Date(x.end), new Date(x.start)).days()} days`: `${new DateDiff( new Date(x.end), new Date(x.start)).hours()} hours`,
          reason: x.sickness_type_id && x.leave_reason_id == 1 ? x.sickness_type.name : x.leave_reason_id ? x.leave_reason.name : 'N/A',
          user: x.user === null? 'N/A':x.user.first_name +' '+ x.user.last_name,
          user_id: x.user.id,
          approved_by: x.administrator === null ? 'N/A' : `${x.administrator.first_name}  ${x.administrator.last_name}`,
          status: x.state.charAt(0).toUpperCase() + x.state.substring(1),
          time:x.is_day?'Full day': `${this.momentFormat(x.start,'HH:mm')}` + ' - ' + `${this.momentFormat(x.end,'HH:mm')}`
        }))

        this.tableLoading = false
        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to

        return dataArray
      }catch(error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
  },

}
</script>
<style>
div#dropdown-1 .btn-outline-danger {
  border: none !important;
}
</style>
